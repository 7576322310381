import React, { useState } from "react";
import { Drawer, Button, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Login from "../../pages/Login";

const MobileMenu = () => {
  const [visible, setVisible] = useState(false);
  const StyledMenu = styled.div`
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: none;
    }
    a {
      font-size: x-large;
      font-weight: 600;
      padding: 1em;
      margin: 3em;
    }
    li.ant-menu-item.ant-menu-item-only-child:hover {
      background-color: #f0f0f0;
    }
    li.ant-menu-item.ant-menu-item-only-child:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
    .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item {
      margin: 0;
      height: max-content;
      padding: 1em;
    }
  `;

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const items = [
    { path: "/needLawyer", label: "I need a Lawyer" },
    { path: "/IamLawyer", label: "I am a Lawyer" },
    { path: "/Class_Action", label: "Class Actions" },
    { path: "/About_us", label: "About Us" },
    { path: "/Contactus", label: "Contact" },
    { path: "/register", label: "Sign Up" },
    { path: "#", label: "Login", onClick: showDrawer }
  ];

  return (
    <>
      <Button style={{fontSize: "x-large", height: "min-content", lineHeight: 0}} type="text" onClick={showDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer
        title="Menu"
        onClose={closeDrawer}
        open={visible}
      >
        <StyledMenu>
          <Menu mode="vertical" className="d-flex flex-column">
            {items.map((menuItem, index) => (
              <Menu.Item key={index} className="justify-content-center">
                {menuItem.path === "#" ? (
                  <a onClick={menuItem.onClick}>{menuItem.label}</a>
                ) : (
                  <Link to={menuItem.path}>{menuItem.label}</Link>
                )}
              </Menu.Item>
            ))}
          </Menu>
        </StyledMenu>
      </Drawer>
    </>
  );;
};

export default MobileMenu;