import React, { useEffect, useState } from 'react';
import { Avatar, message } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon, UserDropDwonInfo } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { Router } from 'react-router-dom/cjs/react-router-dom';
import { APP_URLs } from '../../../constants';

// Menu bar Account Button Avatar
const AuthInfo = () => {
  const dispatch = useDispatch();

  const brief = JSON.parse(localStorage.getItem('brief'));

  const [userBrief, setUserBrief] = useState(localStorage.getItem('brief'));


  // watch for changes in localStorage
  useEffect(() => {

    setUserBrief(brief);
  }, []);



  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut())
      .then(() => {
        // history.push('/account-service');
        window.location.reload();
      })
      .catch(err => {
        message.error("Please try again or email us.");
        console.log('Errors:', err);
      });
  };
  
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../../src/static/img/avatar/chat-auth.png').default} alt="" />
          <figcaption>
            <Heading as="h5">{userBrief?.first_name} {userBrief?.last_name}</Heading>
            <p>{userBrief?.accountType}</p>
          </figcaption>
        </figure>
        {/* <Router> */}
        <ul className="user-dropdwon__links">
          <li>
            {/* link to separate settings page */}
          {userBrief?.accountType === 'Client' ? (
            <a href={APP_URLs.clientPortal + "account-service/settings"}>
              <FeatherIcon icon="settings" /> Settings
            </a>
          ) : (
            <a href={APP_URLs.solicitorPortal + "account-service/settings"}>
              <FeatherIcon icon="settings" /> Settings
            </a>
          )}
          </li>
        </ul>
        {/* </Router> */}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="hover">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
