import React, { useState } from 'react';
import { Radio } from 'antd';
import {Link} from 'react-router-dom';

const imgPath = `${process.env.PUBLIC_URL}/images/`;
const options = [
  { value: 1, description: "Original" },
  { value: 2, description: "Gradient icon & text" },
  { value: 3, description: "Dual-tone icon & text" },
  { value: 4, description: "Dual-tone icon & gradient text" },
  { value: 5, description: "Combine icon & text" },
];
const Logo = () => {
  const [selectedValue, setSelectedValue] = useState(1);

  const handleChange = e => {
    setSelectedValue(e.target.value);
  };

  return (
    <>
      <Radio.Group onChange={handleChange} value={selectedValue} style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        background: "white",
        padding: "10px",
        border: "1px solid #d9d9d9",
        borderRadius: "4px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.25)",
      }}>
        {options.map((option) => <Radio key={option.value} value={option.value}>{option.description}</Radio>)}
      </Radio.Group>
      <Link className="home-link" to="/" title="homepage">
        <img
          id="logo-img"
          width={"230px"}
          className="img-fluid auto_size"
          src={imgPath + `logo-${selectedValue}.png`}
          alt="Take My Case logo"
        />
      </Link>
    </>
  );
}

export default Logo;
