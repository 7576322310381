import React, { useState } from "react";
import LegalMaster from "../common/LegalMaster";

export const Footer = () => {
  const [modal, setModal] = useState(null);

  const links = [
    { order: 1, title: "Privacy policy" },
    { order: 2, title: "Data Security" },
    { order: 3, title: "Acceptable Use Policy" },
    { order: 4, title: "Cookie Policy" },
    { order: 5, title: "Terms of Use for Lawyers" },
    { order: 6, title: "Terms of Use for Consumers" },
    // { order: 7, title: "Useful Resources" },
    { order: 8, title: "Q & A's" },
  ];

  const renderLinks = () => {
    return links.map((link) => (
      <li key={link.order}>
        <div className="post-detail">
          <button onClick={() => setModal(link.order)}>{link.title}</button>
        </div>
      </li>
    ));
  };

  function getYear() {
    return new Date().getFullYear();
  }

  const handleClick = () => {
    setModal(0);
  };

  return (
    <>
      <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-md-flex align-items-center justify-content-between">
                <div className="featured-icon-box icon-align-before-content style2">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                      <i className="flaticon flaticon-recruitment"></i>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Find the Right Lawyer to Take Your Case</h3>
                    </div>
                    {/* <div className="featured-desc">
                        <p>
                          We have over 30 years experience oft Business porro
                          qusquam dol ipsum quia dolor sit amet.
                        </p>
                      </div> */}
                  </div>
                </div>
                {/* <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/register"}
                  >
                    Get Started
                  </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
        <div className="second-footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                <div className="widget widget_text clearfix">
                  <div className="footer-logo">
                    <img
                      id="footer-logo-img"
                      className="img-fluid auto_size"
                      height="40"
                      width="162"
                      src={process.env.PUBLIC_URL + "/images/logo.png"}
                      alt="footer-logo"
                    />
                  </div>
                  <p>
                    Finding the right lawyer to take on your case is so
                    important. Litigation or court action is stressful and
                    complex. Our website will match your case and your personal
                    financial circumstances to the right legal professional who
                    specializes in the right area and on terms that suit you.
                  </p>
                  <div className="d-flex align-items-center pt-15 pb-25">
                    <div className="social-icons">
                      <ul className="social-icons list-inline">
                        <li>
                          <a rel="noopener" aria-label="facebook"
                            href="#facebook"
                          >
                            <i className="ti ti-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#linkedin" aria-label="linkedin"
                            rel="noopener"
                          >
                            <i className="ti ti-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget-recent-post clearfix">
                  <h3 className="widget-title">For Legal Professionals</h3>
                  <ul className="widget-post ttm-recent-post-list">
                    {renderLinks()}
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget-contact clearfix">
                  <h3 className="widget-title">Contact Us</h3>
                  <ul className="widget_contact_wrapper">
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                      <h3>Call Us On:</h3>1300 287 529
                    </li>
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                      <h3>Address:</h3>166 Wickham Terrace, Brisbane City, QLD
                      4000
                    </li>
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-email"></i>
                      <h3>Email:</h3>
                      <a href="mailto:info@takemycase.com.au">
                        info@takemycase.com.au
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <span className="copyright">
                  Copyright © {getYear()} Take&nbsp;My&nbsp;Case&trade; All rights reserved.
                </span>
              </div>
              <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
              </div>
            </div>
          </div>
        </div>
        <LegalMaster SetModalVal={modal} handleClick={handleClick} />
      </footer>
    </>
  );
};
