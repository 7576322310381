const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  SIGNUP_BEGIN: 'SIGNUP_BEGIN',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERR: 'SIGNUP_ERR',

  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERR: 'RESET_PASSWORD_ERR',

  loginBegin: () => ({
    type: actions.LOGIN_BEGIN,
  }),

  loginSuccess: data => ({
    type: actions.LOGIN_SUCCESS,
    data,
  }),

  loginErr: err => ({
    type: actions.LOGIN_ERR,
    err: {
      status: err.response.status,
      message: err.message
    },
  }),

  logoutBegin: () => ({
    type: actions.LOGOUT_BEGIN,
  }),

  logoutSuccess: data => ({
    type: actions.LOGOUT_SUCCESS,
    data,
  }),

  logoutErr: err => ({
    type: actions.LOGOUT_ERR,
    err,
  }),

  signupBegin: () => ({
    type: actions.SIGNUP_BEGIN,
  }),

  signupSuccess: (data) => ({
    type: actions.SIGNUP_SUCCESS,
    data,
  }),

  signupErr: (err) => ({
    type: actions.SIGNUP_ERR,
    err,
  }),

  resetPasswordBegin: () => ({
    type: actions.RESET_PASSWORD_BEGIN,
  }),

  resetPasswordSuccess: () => ({
    type: actions.RESET_PASSWORD_SUCCESS,
  }),

  resetPasswordErr: (err) => ({
    type: actions.RESET_PASSWORD_ERR,
    err,
  }),
};

export default actions;
