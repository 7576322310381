import React from "react";
import { Dropdown, Space } from "antd";
import { Link } from 'react-router-dom';

export const SplitString = ({ text = null }) => {
  return text.split("\n").map((str) => <label>{str}</label>);
};

export const CasePostButton = ({ Buttontext = null, useClasses = null }) => {
  const items = [
    {
      label: (
        <Link to={"/Post_Case"}>Indivudial Case</Link>
      ),
      key: 0,
    },
    { type: "divider" },
    {
      label: (
        <Link rel="noopener noreferrer" to={"/Class_Action"}>Class Action Case</Link>
      ),
      key: 1,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a style={{fontSize: "1.2em"}} className={useClasses} onClick={(e) => e.preventDefault()}>
          <Space>{Buttontext}</Space>
        </a>
      </Dropdown>
    </>
  );
};

export const DrawerChild = ({ toggleDrawer }) => (
  <button onClick={toggleDrawer}>Join</button>
);
