import React from "react";
import { Button } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { APP_URLs } from '../constants';

const Login = () => {
  return (
    <div className="p-2">
      <Button
        icon={<UserOutlined />}
        size="large"
        href={APP_URLs.clientPortal}
        style={{ margin: "10px", fontSize: "2em", padding: "1em", height: "1.8em" }}
      >
        Login as a Client
      </Button>
      <Button
        icon={<TeamOutlined />}
        size="large"
        href={APP_URLs.solicitorPortal}
        style={{ margin: "10px", fontSize: "2em", padding: "1em", height: "1.8em" }}
      >
        Login as a Solicitor
      </Button>
    </div>
  );
};

export default Login;