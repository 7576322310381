import React, { useEffect, useState, lazy, Suspense } from "react";
import { Modal } from "antd";

const AcceptableUsePolicy = lazy(() =>
  import("../../pages/legalProfessionals/AcceptableUsePolicy")
);
const TCConsumer = lazy(() =>
  import("../../pages/legalProfessionals/TCConsumer")
);
const PrivacyPolicy = lazy(() =>
  import("../../pages/legalProfessionals/PrivacyPolicy")
);
const CookiePolicy = lazy(() =>
  import("../../pages/legalProfessionals/CookiePolicy")
);
const LawyersTermsOfUse = lazy(() =>
  import("../../pages/legalProfessionals/LawyersTermsOfUse")
);
const DataSecurity = lazy(() =>
  import("../../pages/legalProfessionals/DataSecurity")
);
const QA = lazy(() => import("../../pages/legalProfessionals/QAS"));

const LegalMaster = ({ SetModalVal = 0, handleClick }) => {
  const [modalValue, setModalValue] = useState({
    isModalOpen: false,
    openModalFor: 0,
  });

  useEffect(() => {
    SetModalOpen(SetModalVal);
  }, [SetModalVal]);

  function SetModalOpen(modalVal) {
    setModalValue({
      isModalOpen: true,
      openModalFor: modalVal,
    });
  }

  function getTermProp() {
    let component;
    switch (modalValue.openModalFor) {
      case 1:
        component = <PrivacyPolicy />;
        break;
      case 2:
        component = <DataSecurity />;
        break;
      case 3:
        component = <AcceptableUsePolicy />;
        break;
      case 4:
        component = <CookiePolicy />;
        break;
      case 5:
        component = <LawyersTermsOfUse />;
        break;
      case 6:
        component = <TCConsumer />;
        break;
      case 7:
        component = null;
        break;
      case 8:
        component = <QA />;
        break;
    }
    return <Suspense fallback={<div>Loading...</div>}>{component}</Suspense>;
  }

  function closeModalOpen() {
    setModalValue({ isModalOpen: !modalValue.isModalOpen, openModalFor: 0 });
    handleClick();
  }

  return (
    <>
      {modalValue.openModalFor ? (
        <section>
          <Modal
            open={modalValue.isModalOpen}
            closable={true}
            onCancel={closeModalOpen}
            footer={null}
            width={1000}
          >
            <div className="col-12">
              {modalValue.isModalOpen ? getTermProp() : null}
            </div>
          </Modal>
        </section>
      ) : null}
    </>
  );
};

export default LegalMaster;
