import React, { lazy, Suspense, useEffect } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/vendor/flaticon.css";
import "./css/vendor/fontawesome.min.css";
import "./css/vendor/themify-icons.css";
import "antd/dist/antd.css";
import Home from "./pages/Home";
import ScrollToTop from "./components/layout/Gotop";
import { Spin, ConfigProvider } from "antd";
import store from "./redux/store";
import axios from "axios";
import actions from "./redux/authentication/actions";
import { Provider, useDispatch, useSelector } from "react-redux";
import config from "./config/config";
import { ThemeProvider } from 'styled-components';
import './static/css/index.css';

const About_us = lazy(() => import("./pages/About_us"));
const Contact_01 = lazy(() => import("./pages/Contact_01"));
const Error_404 = lazy(() => import("./pages/Error_404"));
const Error_405 = lazy(() => import("./pages/Error_405"));
const NeedLawyer = lazy(() => import("./pages/NeedLawyer"));
const IamLawyer = lazy(() => import("./pages/IamLawyer"));
const Register = lazy(() => import("./pages/Register"));
const CasePost = lazy(() => import("./pages/Post_case"));
const Class_Action = lazy(() => import("./pages/classAction/ClassActionIndex"));
const SearchClassActions = lazy(() => import("./pages/classAction/SearchClassActionCase"));
const RegisterNewClassAction = lazy(() => import("./pages/ClassAction"));
const ViewClassActionCase = lazy(() => import("./pages/classAction/ViewClassActionCase") );
const Post_successful = lazy(() => import("./pages/Post_successful") );
const Test = lazy(() => import("./pages/legalProfessionals/LawyersTermsOfUse") );

const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { loginSuccess } = actions;
  const { rtl } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });


  useEffect(() => {
    /* get token from localStorage */
    // const token = localStorage.getItem('authToken');

    /* get token and brief from cookies */
    const cookieToken = getCookie('authToken');
    const localStorageToken = localStorage.getItem('authToken');
    /* set token and brief to localStorage */
    if (cookieToken || localStorageToken) {
      console.log('setting auth token');
      localStorage.setItem('authToken', cookieToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${cookieToken}`;
      dispatch(loginSuccess(true));
      deleteCookie('authToken');
    }
    else {
      dispatch(loginSuccess(false));
    }
  }, []);

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  function deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl }}>
        <Router basename="/home">
          <Suspense fallback={ <div className="spin"><Spin /></div> }>

            <Route exact path="/"
              component={Home} />

            <Route exact path="/About_us"
              component={About_us} />

            <Route exact path="/Contactus"
              component={Contact_01} />

            {/* <Route exact path="/Job_list"
              component={Job_list} />
            <Route exact path="/Job_details"
              component={Job_details} />
            <Route exact path="/Employers_list"
              component={Employers_list} />
            <Route exact path="/Employers_details"
              component={Employers_details} /> */}

            <Route exact path="/Error_404"
              component={Error_404} />

            <Route exact path="/Error_405"
              component={Error_405} />

            <Route exact path="/Post_Case"
              component={CasePost} />

            <Route exact path="/Class_Action"
              component={Class_Action} />

            <Route exact path="/Register"
              component={Register} />

            <Route exact path="/needLawyer"
              component={NeedLawyer} />

            <Route exact path="/IamLawyer"
              component={IamLawyer} />

            <Route exact path="/searchClassActions"
              component={SearchClassActions} />

            <Route exact path="/PostNewClassAction"
              component={RegisterNewClassAction} />

            <Route path="/viewClassActionCase/:caseId"
              component={ViewClassActionCase} />

            <Route exact path="/Post_successful"
              component={Post_successful} />

            <Route exact path="/test"
              component={Test} />

            <ScrollToTop />
          </Suspense>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <div className="page">
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
    </div>
  );
}

export default App;
