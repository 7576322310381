import axios from 'axios';

import actions from './actions';
import { APP_URLs } from '../../constants';

const { loginBegin, loginSuccess, loginErr,
  logoutBegin, logoutSuccess, logoutErr,
  signupBegin, signupSuccess, signupErr,
  resetPasswordBegin, resetPasswordSuccess, resetPasswordErr
} = actions;

// axios error structure
// const error = {
//   message: "An error occurred.", // Error message
//   response: {
//     data: {}, // Response data (if available)
//     status: 404, // HTTP status code
//     statusText: "Not Found", // HTTP status text
//     headers: {}, // Response headers
//     config: {}, // Axios request config
//   },
// };

const login = (username, password) => {
  return async dispatch => {
    dispatch(loginBegin());
    return axios.post(APP_URLs.backend + 'login', {
      username,
      password
    }).then(response => {
      if (response.status === 200) {
        localStorage.setItem('authToken', response.data.access_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
        // get brief data
        localStorage.setItem('brief', JSON.stringify(response.data.brief));
        dispatch(loginSuccess(true));
      } else {
        throw new Error('Login failed');
      }
    }).catch(err => {
      dispatch(loginErr(err));

      // Re-throw the error to allow the calling function to catch it
      throw err;
    });
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      localStorage.removeItem('authToken');
      delete axios.defaults.headers.common['Authorization'];
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err.response));
    }
  };
};

const signup = (userData) => {
  return async (dispatch) => {
    dispatch(signupBegin());
    // Return the axios.post promise
    return axios.post(APP_URLs.backend + 'createNewAccount', userData)
      .then(response => {
        if (response.status === 200) {
          dispatch(signupSuccess(response.data));
        } else {
          throw new Error('Signup failed');
        }
      })
      .catch(err => {
        // Check if the error response has the expected format
        if (err.response && err.response.data && err.response.data.errors) {
          dispatch(signupErr(err.response.data.errors));
        } else {
          // For any other errors
          dispatch(signupErr(err.message));
        }
        // Re-throw the error to allow the calling function to catch it
        throw err;
      });
  };
};

const resetPassword = (passwordData) => {
  return async (dispatch) => {
    dispatch(resetPasswordBegin());
    try {
      const response = await axios.post(APP_URLs.backend + 'reset-password', passwordData);
      if (response.status === 200) {
        dispatch(resetPasswordSuccess());
        return response;
      } else {
        console.log(response);
        dispatch(resetPasswordErr(response));
      }
    } catch (err) {
      dispatch(resetPasswordErr(err.response));
      throw err;
    }
  };
};

const checkAuth = (history) => {
  return async dispatch => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    try {
      const response = await axios.get(APP_URLs.backend + 'check-auth');
      if (response.status === 200) {
        dispatch(loginSuccess(true));
      } else {
        console.log("CheckAuth no auth Logout");
        dispatch(logOut());
        history.push("/account-security");
      }
    } catch (err) {
      console.log("CheckAuth Error Logout", err);
      dispatch(logOut());
      history.push("/account-security");
    }
    return !!token;
  };
};

export { login, logOut, signup, resetPassword, checkAuth };
