import React from "react";
import Slider from "react-slick";
import Header from "../components/layout/Header";
import { Banner } from "../components/banner/Home1_banner";
import { Footer } from "../components/layout/Footer";

// const imgPath = `images/homepage/`;
// const svgPath = `svg/`;
const imgPath = `${process.env.PUBLIC_URL}/images/homepage/`;
const bgImgPath = `${process.env.PUBLIC_URL}/images/bg-image/`;
const svgPath = `${process.env.PUBLIC_URL}/svg/`;
// console.log(`${process.env.PUBLIC_URL}/images/homepage/`);
var slick_slider = {
  dots: false,
  arrow: false,
  autoplay: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 2,
  rows: 1,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const servicesBoxesText = [
  {
    icon: "flaticon-research",
    title: "List your case",
    description: "List your case so you can find the best lawyer for your case"
  },
  {
    icon: "flaticon-recruitment-7",
    title: "Connect",
    description: "Connect with the lawyer of your choice who is matched to your case and your budget including no win no fee."
  },
  {
    icon: "flaticon-recruiting",
    title: "Go back-and-forth",
    description: "Communicate with the lawyer via email, text or phone. Include documents, photos, videos and more."
  },
  {
    icon: "flaticon-recruitment-7",
    title: "Sign up",
    description: "After you have selected the lawyer of your choice on your budget, sign up with them."
  },
  {
    icon: "flaticon-recruitment",
    title: "Stay protected",
    description: "Your case is listed confidentially and only expert or accredited lawyers who specialise in the area of your case will represent you."
  },
];

const ServicesBox = ({ icon, title, description }) => (
  <div className="col-lg-4 col-md-6 col-sm-6">
    <div className="featured-icon-box icon-align-before-title style3">
      <div className="featured-icon">
        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
          <i className={icon}></i>
        </div>
      </div>
      <div className="featured-title">
        <h3>{title}</h3>
      </div>
      <div className="featured-content">
        <div className="featured-desc">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
);

const findingALawyerText = [
  "Only accredited specialists are matched to your case",
  "Confidentiality of your identity and your case is assured",
  "Helpline available for any assistance you may need",
  "No cost to you in using our service",
];

const weAreHelpingText = [
  {title: "Insurance claims", content: "Disputes concerning insurance claims.",
    image: "insurance_claims.webp"
  },
  {title: "Motor Vehicle Accident", content: "Disputes concerning motor vehicle accidents involving any property damage or personal injuries.",
    image: "car-accident.webp"
  },
  {title: "Company Disputes", content: "Disputes concerning companies including public companies, proprietary companies or any incorporated body.",
    image: "company_disputes.webp"
  },
  {title: "Contract Disputes", content: "Disputes concerning contracts whether in made in writing or orally. For contracts involving land or real property.",
    image: "contract_disputes.webp"
  },
  {title: "Debt recovery", content: "Cases where you need to recover a debt or dispute a debt.",
    image: "debt_recovery.webp"
  },
  {title: "Partnership Disputes", content: "Disputes concerning partnerships. A Joint venture is a commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities.",
    image: "partnership_disputes.webp"
  },
  {title: "Workplace Health and Safety", content: "Disputes concerning breaches of workplace, health and safety laws.",
    image: "whs.webp"
  },
  {title: "Employment Disputes", content: "Cases involving employment including unfair dismissal.",
    image: "employment_disputes.webp"
  },
  {title: "Bankruptcy Disputes", content: "Disputes over bankruptcy notices or petitions for bankruptcy.",
    image: "banckrupt.webp"
  },
  {title: "Conveyancing Disputes", content: "Disputes concerning conveyancing of land or property including contracts for sale of land, houses and units.",
    image: "conveyancing.webp"
  },
  {title: "International Disputes", content: "Disputes concerning claims against persons or companies overseas.",
    image: "internation_disputes.webp"
  },
  {title: "Advice on liability", content: "General advice on whether you have a case or someone has a case against you.",
    image: "advice.webp"
  },
];

const HelpingBox = ({ image, title, content }) => (
  <div className="col-md-12">
    <div className="featured-imagebox featured-imagebox-services style1">
      <div className="featured-thumbnail">
        <img className="img-fluid" src={imgPath + image} alt={image} />
      </div>
      <div className="featured-content">
        <div className="featured-title">
          <h3>
            <a href={void 0}>{title}</a>
          </h3>
        </div>
        <div className="featured-desc">
          <p>{content}</p>
        </div>
      </div>
    </div>
  </div>
);

const Home = () => {
  return (
    <div className="site-main">
      <Header />
      <Banner />
      <section className="ttm-row features-section bg-theme-GreyColor clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title">Take&nbsp;My&nbsp;Case&trade; Services</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-equal-height mb_10">
            {servicesBoxesText.map(box => <ServicesBox key={box.title} icon={box.icon} title={box.title} description={box.description} />)}
          </div>
        </div>
      </section>

      <section className="ttm-row about-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">
                    Finding a Lawyer for Your Case has never been easier!
                  </h2>
                </div>
                <div className="title-desc">
                  <p>
                    Finding the right lawyer to take on your case is so important.
                    Litigation or court action is stressful and complex. Our website
                    will match your case and your personal financial circumstances to
                    the right legal professional who specializes in the right area and
                    on terms that suit you.
                  </p>
                </div>
              </div>
              <div className="mb-35">
                <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                  {findingALawyerText.map((text, i) => (
                    <li key={i}>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        {text}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-10 mx-auto d-none d-lg-block">
              <div className="mr-40 ml-20 pt-lg-50">
                <div className="bg-theme-WhiteColor">
                  <img alt="bgimage" className="img-fluid"
                    src={svgPath + "home_1.svg"}
                    style={{
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      margin: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
        style={{ backgroundImage: bgImgPath + "row-bgimage-1.png" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h2 className="title">We are helping with...</h2>
                </div>
                <div className="title-desc">
                  <p>
                    At Take&nbsp;My&nbsp;Case&trade; we help everyday Australians with a wide range of cases and match the case with an accredited specialist in that area.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Slider
            className="row slick_slider slick-arrows-style2 pt-20 mb_10"
            {...slick_slider}
            slidesToShow={2}
            rows={2}
            arrows={true}
            autoplay={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: { slidesToShow: 2, slidesToScroll: 2 },
              },
              {
                breakpoint: 575,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
              },
            ]}
          >
            {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
              href={process.env.PUBLIC_URL + "/services_details"}
            >
              read more!
            </a> */}
            {weAreHelpingText.map(item =>
              <HelpingBox key={item.title} image={item.image} title={item.title} content={item.content} />)
            }
          </Slider>
        </div>
      </section>

      <Footer />
    </div>
  );

}

export default Home;
