import React, { useState, useEffect } from "react";
import MenuItem from "./Menu";
import MobileMenu from "./MobileMenu";
import Logo from "./Logo";
import { Modal } from "antd";
import Login from "../../pages/Login";
import { CasePostButton } from "../common/CommonComponent";
import AuthInfo from "../utilities/auth-info/info";
import { useSelector } from "react-redux";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleModal = () => setOpen(!open);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop >= 250);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // isSticky = (e) => {
  //   const header = document.querySelector("header");
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 250
  //     ? header.classList.add("is-Sticky")
  //     : header.classList.remove("is-Sticky");
  // };

  return (
    <>
      <header id="masthead" className={`header ttm-header-style-01 ${isSticky ? 'is-Sticky' : ''}`}>
        <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
          <div className="site-header-menu-inner ttm-stickable-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="site-navigation d-flex align-items-center justify-content-between">
                    <div className="site-branding order-2 order-xl-1">
                      <Logo />
                    </div>

                    <div className="border-box-block order-3 order-xl-2">
                      <div className=" d-flex align-items-center justify-content-between">
                        <MenuItem />
                        <div className="mobile-menu">
                          <MobileMenu />
                        </div>
                        {/* menu end id="header-menu-credential"  href={process.env.PUBLIC_URL + "/login"}*/}
                        <div className="header_extra ml-auto d-flex align-items-center" id="header-menu-credential">
                          <LoggedInState toggle={toggleModal} />
                        </div>
                      </div>
                    </div>
                    <div className="header_btn order-1 order-xl-3">
                      <CasePostButton
                        Buttontext={"Post A Case"}
                        useClasses={"ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal width="max-content" closable={false}
        open={open} footer={null} onCancel={() => setOpen(false)}>
        <Login />
      </Modal>

      {/* <section>
          <MDBContainer>
            <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
              <MDBModalBody>
                <div className="col-12">
                  <Login />
                </div>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </section> */}
    </>
  );
}


const LoggedInState = ({ toggle }) => {
  const isLoggedIn = useSelector(state => state.auth.login);
  // console.log('isLoggedIn:', isLoggedIn);
  if (isLoggedIn) {
    return <AuthInfo />;
  } else {
    return (
      <>
        <a href={void 0} onClick={toggle}>
          Login
        </a>
        <div className="header_social">
          <ul className="social-icons">
            <li>
              <a href={process.env.PUBLIC_URL + "/register"}>
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }
};


export default Header;
