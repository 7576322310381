import React from "react";
import { Link } from 'react-router-dom';

const Menu = () => {
  const menuItems = [
    { path: "/needLawyer", label: "I need a Lawyer" },
    { path: "/IamLawyer", label: "I am a Lawyer" },
    { path: "/Class_Action", label: "Class Actions" },
    { path: "/About_us", label: "About Us" },
    { path: "/Contactus", label: "Contact" }
  ];

  return (
    <nav className="main-menu">
      <ul className="menu">
        {menuItems.map((menuItem, index) => (
          <li key={index} className="mega-menu-item">
            <Link className="mega-menu-link remove-text-transform" to={menuItem.path}>{menuItem.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Menu;
