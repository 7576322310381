import styled from 'styled-components';
export const FormStyle = styled.div`
{
  input,
  select,
  textarea,
  button {
    padding: 10px !important;
    line-height: 1.3;
    border-radius: 6px;
  },
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-btn::before {
    opacity: 1;
    display: block !important
  }
  .form-item .ant-form-item-label > label {
    font-size: 1.2em;
  }
}
`;
export const H1 = styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;

export const H2 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`;

export const H3 = styled.h1`
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
`;

export const H4 = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const H5 = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

export const H6 = styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;